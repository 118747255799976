import React from 'react'
import {Anchor} from 'antd'

import Banner from '../component/dashborad/banner'
import Introdution from '../component/dashborad/introduction'
import ProductionVideo from '../component/dashborad/ProductionVideo'
import Application from '../component/dashborad/application'
import Features from '../component/dashborad/features'
import Footer from '../component/dashborad/footer'

import '../assets/style/dashboard/dashboard.less'
import '../assets/style/common.less'
//导航左侧logo
// import logo from '../assets/image/logo.png';

const Dashborad = () => {
  const {Link} = Anchor
  return (
    <div>
      {/* 首页导航,因为需要页内跳转所以暂未抽出组件 */}
      <div className="header">
        {/* <img src={logo} alt="logo" /> */}
        <p>祺鲸科技</p>
        <Anchor className="anchor" affix="false">
          <Link href="#banner" title="首页"/>
          <Link href="#introdution" title="产品介绍"/>
          <Link href="#application" title="应用产品"/>
          <Link href="#features" title="产品功能"/>
        </Anchor>
      </div>

      {/* 因为antd的Anchor所要跳转的id如果直接加在组件上会发生一些偏差
       *  因此额外增加一个锚点专供跳转使用
       *  下同
       */}
      <div id="banner"></div>
      <Banner/>
      <div id="introdution"></div>
      <Introdution/>
      <ProductionVideo/>
      <div id="application"></div>
      <Application/>
      <div id="features"></div>
      <Features/>
      <Footer/>
    </div>
  )
}

export default Dashborad
