import React from 'react';
import '../../assets/style/dashboard/footer.less';

const Footer = () => {
  return (
    <footer>
      <div className="footer-about">
        <p className="about-title">
          合作洽谈请联系我们
        </p>
        <div className="about-text-row">
          <p className="footer-text">
            邮箱：contact@qjcdss.com
          </p>
          <div className="about-line"></div>
          <p className="footer-text">
            地址：杭州市萧山区钱江世纪城天人大厦30楼
          </p>
        </div>
      </div>
      <div className="footer-line"></div>
      <div className="footer-copyright">
        <p className="footer-text">
          版权所有：杭州祺鲸科技有限公司
        </p>
        <a href="http://beian.miit.gov.cn/" target="blank" className="footer-text">
          备案号：浙ICP备20009194号-1
        </a>
      </div>
    </footer>
  );
};

export default Footer;
