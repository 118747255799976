import React from 'react';
import '../../assets/style/dashboard/banner.less';
import banner_gif from '../../assets/image/banner.gif';

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text-box">
        <p className="banner-title">祺鲸CDSS</p>
        <p className="banner-sub-title">
          采用国际权威医学知识库体系，结合海量CDR数据，并基于知识图谱、自然语言处理等多种AI技术打造高度智能化的临床辅助决策系统
        </p>
      </div>
      <div className="banner-logo">
        <img src={banner_gif} alt=""></img>
      </div>
    </div>
  );
};

export default Banner;
