import React from 'react';
import '../../assets/style/dashboard/features.less';
import features_img from '../../assets/image/features.png';

const MainTextList = [
  {
    title: '检查检验解读',
    content:
      '当医生查阅报告时，根据检验指标和病人年龄、性别、诊断、生理指标等，自动为医生提供检验指标的临床意义解读并应用于推荐内容。',
  },
  {
    title: '检查检验推荐',
    content:
      '根据医生当前初步诊断结合患者基本信息、一诉五史，当医生下处置时，自动推荐合适的检验、检查项，帮忙医生进一步确诊。',
  },
  {
    title: '医学知识搜索和管理',
    content:
      '提供最新最全的医学文献、临床指南、临床路径、药品说明书等循证医学知识，并支持秒级搜索；针对知识点和规则的支持增、删、改、查的管理和维护。',
  },
  {
    title: '治疗推荐',
    content:
      '根据医生当前诊断及患者基本信息、一诉五史，在医生下达医嘱时，自动推荐最佳的治疗方案，并提供这些用药和手术在医院同诊断的病历中开立占比，给医生以进一步参考。',
  },
];

const TextCardList = [
  {
    title: '相似病历分析',
    content: '根据医生在工作站输入的信息，基于医院历史数据找出相似病历，并从不同维度进行统计分析。',
  },
  {
    title: '诊断推荐',
    content:
      '根据输入的患者信息和主诉现病史等信息，当医生下诊断时，自动推荐疑似诊断；并提供这些诊断的典型症状、常用检查、常用检验以及院内相似病历',
  },
  {
    title: '智能提示',
    content:
      '依据患者基本信息、一诉五史，结合医院临床历史数据及诊疗规范，在医生下诊断或开立不适合患者病情的医嘱处置时，自动审核并针对问题给予阻断性提示。',
  },
];

const MainText = (title, content) => {
  return (
    <div className="main-text">
      <p className="main-text-title">{title}</p>
      <p className="main-text-content">{content}</p>
    </div>
  );
};

const TextCard = (title, content) => {
  return (
    <div className="text-card">
      <p className="text-card-title">{title}</p>
      <p className="text-card-content">{content}</p>
    </div>
  );
};

const Features = () => {
  return (
    <div className="features">
      <div className="title-box">
        <p className="title-en">PRODUCT FUNCTION</p>
        <p className="title-zh">产品功能</p>
      </div>
      <div className="features-content">
        <div className="features-img">
          <img src={features_img} alt=""></img>
        </div>
        <div className="main-text-box">
          {MainTextList.map((item) => MainText(item.title, item.content))}
        </div>
        <div className="card-box">
          {TextCardList.map((item) => TextCard(item.title, item.content))}
        </div>
      </div>
    </div>
  );
};

export default Features;
