import React from 'react'
import '../../assets/style/dashboard/introdution.less'
import introdution from '../../assets/image/introdution.png'
import introdution_mobile from '../../assets/image/introducion_mobile.png'

const Introdution = () => {
  return (
    <div className="introdution">
      <div className="introdution-img">
        <img src={introdution} alt="产品简介"></img>
      </div>
      <div className="introdution-mobile-img">
        <img src={introdution_mobile} alt="产品简介"></img>
      </div>
      <div className="introdution-text">
        <p className="title-en">PRODUCT BRIEF INTRODUCTION</p>
        <p className="title-zh">产品简介</p>
        <p className="sub-text">
          采用国际权威医学知识库体系，结合海量CDR数据，并基于知识图谱、自然语言处理等多种AI技术打造高度智能化的临床辅助决策系统，在全面满足国家电子病历系统功能应用水平分级评价、医疗健康信息互联互通标准成熟度测评等相关要求的同时，进一步院方提升医疗质量，降低医疗风险。
        </p>
      </div>
    </div>
  )
}

export default Introdution
