import '../../assets/style/dashboard/production-video.less'
import React, {useState} from 'react'
import {CloseCircleOutlined} from '@ant-design/icons'

const ProductionVideo = () => {
  const videoUrlList = [
    'http://cdss.blessingwhale.com/%E7%A5%BA%E9%B2%B8%E4%B8%B4%E5%BA%8A%E5%86%B3%E7%AD%96%E6%94%AF%E6%8C%81%E7%B3%BB%E7%BB%9F%EF%BC%88%E9%97%A8%E8%AF%8A%E7%89%88%EF%BC%89.mp4',
    'http://cdss.blessingwhale.com/%E7%A5%BA%E9%B2%B8%E5%8C%BB%E5%AD%A6%E7%9F%A5%E8%AF%86%E5%BA%93-%E6%9C%BA%E6%9E%84%E7%9F%A5%E8%AF%86%E7%AE%A1%E7%90%86%E5%90%8E%E5%8F%B0.mp4'
  ]
  const [videoUrl, setVideoUrl] = useState('')

  const [showVideoPanel, setShowVideoPanel] = useState(false)

  const playVideo = (index) => {
    setShowVideoPanel(true)
    setVideoUrl(videoUrlList[index])
    const videoPlayer = document.getElementById('videoPlayer')
    videoPlayer.load()
  }

  const closeVideoPlayer = () => {
    setShowVideoPanel(false)
    setVideoUrl('')
    const videoPlayer = document.getElementById('videoPlayer')
    videoPlayer.pause()
  }

  return (
    <div className="production-video">
      <div className="video-item"
           onClick={() => playVideo(0)}>
        <img src={require('../../assets/image/video.png')}
             alt="祺鲸临床决策支持系统（门诊版）"/>
        <span>祺鲸临床决策支持系统（门诊版）</span>
      </div>
      <div className="video-item"
           onClick={() => playVideo(1)}>
        <img src={require('../../assets/image/video.png')}
             alt="祺鲸医学知识库-机构知识管理后台"/>
        <span>祺鲸医学知识库-机构知识管理后台</span>
      </div>

      <div className={`video-panel ${showVideoPanel ? 'show' : ''}`}>
        <div className="close-icon">
          <CloseCircleOutlined
            onClick={closeVideoPlayer}/>
        </div>
        <video
          id="videoPlayer"
          controls
          autoPlay>
          <source src={videoUrl}/>
        </video>
      </div>
    </div>
  )
}

export default ProductionVideo
