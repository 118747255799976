import React from 'react';
import '../../assets/style/dashboard/application.less';
import advantage_img_1 from '../../assets/image/advantage_image_1.png';
import advantage_img_2 from '../../assets/image/advantage_image_2.png';
import using_1 from '../../assets/image/using_1.png';
import using_2 from '../../assets/image/using_2.png';
import using_3 from '../../assets/image/using_3.png';

const Application = () => {
  return (
    <div className="application">
      <div className="advantage">
        <div className="advantage-text">
          <p className="title-en">CORE ADVANTAGE</p>
          <p className="title-zh">核心优势</p>
          <p className="detail sub-text">
            祺鲸科技CDSS是基于权威循证医学知识和真实世界临床数据分析模型，具有深度学习能力的人工智能系统。
          </p>
        </div>
        <div className="advantage-card-box">
          <div className="advantage-card">
            <div className="advantage-card-item">
              <div className="advantage-card-image">
                <img src={advantage_img_1} alt="权威可靠" />
              </div>
              <div className="advantage-card-title">权威可靠</div>
              <div className="sub-text">权威循证医学知识库</div>
            </div>
            <div className="advantage-card-item">
              <div className="advantage-card-image">
                <img src={advantage_img_2} alt="量化可计算" />
              </div>
              <div className="advantage-card-title">量化可计算</div>
              <div className="sub-text">真实世界医疗大数据</div>
            </div>
          </div>
        </div>
      </div>
      <div className="using">
        <div className="using-text-box">
          <p className="title-en">APPLICATION SCENARIOS</p>
          <p className="title-zh">应用场景</p>
        </div>
        <div className="using-bar-box">
          <div className="using-bar">
            <div className="using-slidebar"></div>
            <img className="using-img" src={using_1} alt=""></img>
            <p className="using-text">公立医院</p>
          </div>
          <div className="using-bar">
            <div className="using-slidebar"></div>
            <img className="using-img" src={using_2} alt=""></img>
            <p className="using-text">基层医疗结构</p>
          </div>
          <div className="using-bar">
            <div className="using-slidebar"></div>
            <img className="using-img" src={using_3} alt=""></img>
            <p className="using-text">互联网诊所</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
